.container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.total {
    margin-right: 10px;
}

.currency {
    margin-right: 40px;
}

.button {
    margin-right: 16px;
}