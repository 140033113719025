.section {
    width: 600px;
}

.title {
    margin-top: 40px;
    margin-bottom: 20px;
}

.tab_container {
    display: flex;
    margin-bottom: 40px;
}

.ingredients {
    overflow: auto;
    height: 620px;
}

.ingredients::-webkit-scrollbar {
    width: 8px;
    background-color: #2F2F37;
}

.ingredients::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}