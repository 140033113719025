.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order {
    margin-top: 16px;
}

.text1 {
    margin-top: 32px;
}

.text2 {
    margin-top: 60px;
}

.text3 {
    margin-top: 8px;
    margin-bottom: 120px;
}

.image {
    max-width: 120px;
    height: auto;
    margin-top: 60px;
}