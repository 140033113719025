.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.image {
    max-width: 480px;
    height: auto;
}
.name {
    margin-top: 16px;
}
.indicators {
    width: 520px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 60px;
}
.indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 56px;
    justify-content: space-between;
}