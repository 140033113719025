.container {
    background-color: #1C1C21;
    padding: 24px;
    border-radius: 40px;
}

.number_date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
}

.name {
    margin-bottom: 8px;
}

.status {
    margin-bottom: 24px;
}

.compound {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.images {
    display: flex;
    flex-direction: row;
}

.price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.link {
    text-decoration: inherit;
    color: inherit;
}