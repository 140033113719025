.container {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: auto;
    height: 800px;
}

.container li {
    list-style: none;
}

.container::-webkit-scrollbar {
    width: 8px;
    background-color: #2F2F37;
}

.container::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}