.container {
    width: 480px;
}

.name {
    margin-top: 80px;
}

.email {
    margin-top: 24px;
}

.password {
    margin-top: 24px;
}

.button_container {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}