.container {
    display: grid;
    grid-template-columns: 1fr 1fr 4fr 2fr;
    align-items: center;
    width: 1240px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 19px;
    padding-bottom: 19px;
    padding-left: 0;
    padding-right: 0;
}

.item {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    color: inherit;
    text-decoration: none;
}

.item_logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container li {
    list-style: none;
}

.item_profile {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: end;
    color: inherit;
    text-decoration: none;
}