.detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.number {
    margin-bottom: 40px;
}

.name {
    margin-bottom: 12px;
    align-self: start;
}

.status {
    color: #00CCCC;
    align-self: start;
    margin-bottom: 60px;
}

.compound_title {
    align-self: start;
    margin-bottom: 24px;
}

.compound_container {
    margin: 0;
    padding: 0;
    overflow: auto;
    height: 366px;
    align-self: start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
    width: 640px;
}

.compound_container::-webkit-scrollbar {
    width: 8px;
    background-color: #2F2F37;
}

.compound_container::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}

.compound_container li {
    list-style: none;
}

.compound_item {
    width: 608px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.compound_name {
    margin-right: auto;
}

.compound_price {
    display: flex;
    width: 130px;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 24px;
}

.date_total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 640px;
}

.price_value {
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}