.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title {
    margin-top: 180px;
}
.email {
    margin-top: 24px;
}
.password {
    margin-top: 24px;
}
.button {
    margin-top: 24px;
}
.new_user {
    margin-top: 80px;
}
.forgot_password {
    margin-top: 16px;
}
