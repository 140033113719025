.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title {
    margin-top: 180px;
}
.name {
    margin-top: 24px;
}
.email {
    margin-top: 24px;
}
.password {
    margin-top: 24px;
}
.button {
    margin-top: 24px;
}
.already {
    margin-top: 80px;
}