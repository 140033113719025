.container {
    width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menu {
    margin: 0;
    margin-top: 120px;
    padding: 0;
    width: 320px;
}

.menu li {
    list-style: none;
}

.menu_item {
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menu_item a {
    color: inherit;
    text-decoration: none;
}

.description {
    margin-top: 80px;
}

.content {
    margin-top: 40px;
    width: 860px;
}