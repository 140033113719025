.container {
    width: 1240px;
    margin: 0 auto;
}

.title {
    margin-top: 40px;
}

.feed {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    height: 716px;
}

.list {
    width: 600px;
    overflow: auto;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.list li {
    list-style: none;
}

.list::-webkit-scrollbar {
    width: 8px;
    background-color: #2F2F37;
}

.list::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}

.status {
    width: 580px;
    display: flex;
    flex-direction: column;
}

.ready_work {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}

.title_ready_work {
    margin-bottom: 24px;
}

.ready {
    margin-right: 36px;
}

.num_order {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    height: 156px;
    width: 272px;
    overflow: auto;
}

.num_order::-webkit-scrollbar {
    width: 8px;
    background-color: #2F2F37;
}

.num_order::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}

.totalall_value {
    margin-bottom: 60px;
}

.link {
    text-decoration: inherit;
    color: inherit;
}