.border {
    background-image: linear-gradient(to right, #801AB2, #4C4CFF);
    background-size: cover;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 2px;
}

.image {
    background-color: rgba(28, 28, 33, 1);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}