.product_list {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    margin-bottom: 40px;
    row-gap: 32px;
    column-gap: 24px;

}
.name {
    margin-bottom: 24px;
}

.product_list li {
    list-style: none;
}