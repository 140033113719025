.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 272px;
    height: 208px;
    margin-left: 0;
    position: relative;
}

.image {
    margin-bottom: 4px;
}
.price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.name {
    text-align: center;
}
