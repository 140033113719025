.section {
    width: 600px;
    padding-top: 100px;
}

.container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
    height: 560px;
    overflow: auto;
    margin-bottom: 40px;
}

.container::-webkit-scrollbar {
    width: 8px;
    background-color: #2F2F37;
}

.container::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}

.container li {
    list-style: none;
}