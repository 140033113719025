.feed {
    border-radius: 40px;
    background: #1C1C21;
    padding: 24px;
}

.identifier {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.compound {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.images {
    display: flex;
    flex-direction: row;
}

.price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.link {
    text-decoration: inherit;
    color: inherit;
    display: flex;
    flex-direction: column;
    gap: 24px;
}