.modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    border-radius: 40px;
    z-index: 99;
    background-color: #1C1C21;
}

.container {
    display: flex;
    justify-content: center;
}

.title {
    width: 640px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}